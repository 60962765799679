.fileupload-bg > .filepond--drop-label {
    background-color: #eee !important;
    color: #000;
    border: 1px solid #eee;
    border-radius: 10px;
}

.table-layout-fixed {
    table-layout: fixed;
}

.new-line {
    white-space: pre-line;
}

img {
    max-width: 100px;
}

.fg-bg {
    position: relative;
}

.fg-bg:before {
    background-image: url("/src/assets/img/fg-bg.jpg");
    opacity: 0.5;
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 12%;
    background-size: cover;
}

.currency:before,
td[data-colindex="5"]:before {
    content: "S$";
    float: left;
    padding-left: 5px;
}
.currency{
    text-align: right;
}
.quotation_min_height{
   min-height: 60px;
}
/* Style the table container */
.table-container {
    overflow-x: scroll;
    max-width: 100%; /* Set the maximum width of the container */
    margin-bottom: 15px; /* Add some space for the buttons */
    border: 1px solid #ccc; /* Add a border for visual separation */
  }
  
  /* Hide the native scrollbar */
  .table-container::-webkit-scrollbar {
    display: none;
  }
  
  .fixed-header {
    position: sticky;
  
    top: 14px;
    z-index: 999;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-w-navbar {
    position: sticky;
    /* top: 66px; */
    z-index: 4;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}
 
.content{
    padding: 15px 15px !important;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

.render-above {
    z-index: 1048 !important;
}

.render-above-1 {
    z-index: 1049 !important;
}

.fc-event-time, .fc-event-title { /* https://stackoverflow.com/a/48745301 */
    margin: 1px;
    white-space: normal;
}

.fixed-header-filter {
    position: sticky;
    top: 66px;
    z-index: 1000;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
}

.fixed-header-filter-invoice {
    position: sticky;
    top: 235px;
    z-index: 10;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-generate-pdf-invoice {
    position: sticky;
    /* top: 309px; */
    z-index: 3;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-filter-enrollments {
    position: sticky;
    top: 326px;
    z-index: 10;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-navigation-payments {
    position: sticky;
    /* top: 146px; */
    z-index: 3;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-navigation-changes {
    position: sticky;
    /* top: 160px; */
    z-index: 3;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-header-navigation-cn {
    position: sticky;
    /* top: 300px; */
    z-index: 3;
    padding: 0;
    background-color: #fff; /* You can adjust the background color as needed */
   
}

.fixed-table-header{
    position: sticky;
    z-index: 3;
    background-color: #fff;
}

.z-index-100 {
    /* position: relative; */
    z-index: 100 !important;
}


.double-scrollbar-table-style {
    overflow: hidden;
    border-top: none;
}

.double-scrollbar-table-style .th,
.double-scrollbar-table-style .td {
    background-color: #fff;
    overflow: hidden;
    border-top: none;
}

.double-scrollbar-table-style.sticky {
    overflow: scroll;
}

.double-scrollbar-table-style.sticky .header,
.double-scrollbar-table-style.sticky .footer {
    position: sticky;
    z-index: 1;
    width: fit-content;
}

.double-scrollbar-table-style > :not(:first-child) {
    border-top: none;
}