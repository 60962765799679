.modal {
    font-family: arial;
  }
  
  .modal_additional {
    position: fixed;
    width: 80%;
    height: 70%;
    z-index: 1040;
    top: 10%;
    left: 11%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: inherit;
  }

  .modal_withdraw_cn {
    position: fixed;
    width: 50%;
    height: 50vh;
    z-index: 1040;
    top: 20%;
    left: 26%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: inherit;
  }

  .modal-footer-withdraw-cn {
    border-radius: 0;
    bottom:0px;
    position:absolute;
    width:100%;
  }
  
  .backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
  }
  
  .modal-title {
    font-weight: 500;
    font-size: 1.25rem;
  }
  
  .modal-header {
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
  }
  
  .modal-desc,
  .modal-header,
  .modal-footer {
    padding: 12px;
  }
  
  .close-button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    border: none;
    background-color: white;
    opacity: 0.7;
    cursor: pointer;
  }
  button {
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 12px;
    border-radius: 5px;
    border: none;
  }
  
  .modal-footer {
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .secondary-button {
    color: #fff;
    background-color: #6c757d;
  }
  .primary-button {
    color: #fff;
    background-color: #007bff;
  }
  
  .modal-example {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }